import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
class Breadcrumb extends React.Component {  
  componentDidMount() {
    // const $ = require('jQuery');
    // window.ATL_JQ_PAGE_PROPS =  {
    //   "triggerFunction": function(showCollectorDialog) {
    //     $("#jiraFeedbackCollector").click(function(e) {
    //       e.preventDefault();
    //       showCollectorDialog();
    //     });
    //   }
    // };
  }

  render() {
    const { slug, editUrl } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark {
              edges {
                node {
                  frontmatter {
                    title
                  }
                  fields {
                    slug
                  }
                }
              }
            }
            # allYaml {
            #   edges {
            #     node {
            #       info {
            #         title
            #       }
            #       fields {
            #         slug
            #       }
            #     }
            #   }
            # }
          }
        `}
        render={({ allMarkdownRemark, allYaml }) => {
          const slugToTitleMap = {};
          allMarkdownRemark.edges.forEach(edge => {
            slugToTitleMap[edge.node.fields.slug] = edge.node.frontmatter.title;
          });
          // allYaml.edges.forEach(edge => {
          //   slugToTitleMap[edge.node.fields.slug] = edge.node.info.title;
          // });
          let breadcrumbPathPrefix = "/docs";
          if (`${process.env.NODE_ENV}` === "development") {
            breadcrumbPathPrefix = "";
          }
          return (
            <BreadcrumbWrapper>
              <BreadcrumbBootstrap>
                <ReactBootstrap.Breadcrumb.Item href={breadcrumbPathPrefix + "/"}>
                  <i className="fas fa-home" />
                </ReactBootstrap.Breadcrumb.Item>
                  {slug &&
                    slug.split("/").map(breadcrumb => {
                      const url = `${slug.substr(
                        0,
                        slug.indexOf(breadcrumb)
                      )}${breadcrumb}/`;
                      return (
                        breadcrumb !== "" && (
                          <ReactBootstrap.Breadcrumb.Item
                            href={breadcrumbPathPrefix + url}
                            key={url}
                          >
                            {slugToTitleMap[url]}
                          </ReactBootstrap.Breadcrumb.Item>
                        )
                      );
                    })}
                  </BreadcrumbBootstrap>
                  {/* <FeedbackCollector>
                    <a
                      id="jiraFeedbackCollector"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-auto mr-4"
                      >
                        <i className="fas fa-comments" /> Feedback
                    </a>
                  </FeedbackCollector> */}
                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={editUrl}
                      className="ml-auto mr-4"
                    >
                      <i className="fas fa-edit" /> Edit
                    </a>
                  </div>
            </BreadcrumbWrapper>
          );
        }}
      />
    );
  }
}

const BreadcrumbWrapper = styled.div.attrs({
  className: "d-flex flex-row align-items-center"
})`
  background-color: #e9ecef;
  .breadcrumb {
    margin: 0;
    a {
      color: #212529;
    }
  }
`;

const BreadcrumbBootstrap = styled(ReactBootstrap.Breadcrumb)`
  display: flex;
  flex: 1;
`

const FeedbackCollector = styled.div.attrs({})`
  cursor: pointer;
`
export default Breadcrumb;

