import _ from "lodash";
import { GIT_URL } from "../constants/url";

export const getChildrenForGivenParent = (parent, documents) =>
  _.filter(documents.edges, edge => {
    return edge.node.fields.parent === parent;
  });

// List of all the api-references are grouped according to the folder structure
export const getAllApiReferences = (folders, apiReferences) => {
  let apiForAllFolders, apiForAFolder, folderName;
  folders.map(folder => {
    folderName = folder.node.fields.current;
    apiForAFolder = getChildrenForGivenParent(folderName, apiReferences);
    if (!_.isEmpty(apiForAFolder)) {
      apiForAllFolders = apiForAllFolders || {};
      apiForAllFolders[folderName] = apiForAFolder;
    }

    return null;
  });
  return apiForAllFolders;
};

export const getGitHubUrl = (slug = "", isCurrentNodeAParent = false, type) => {
  const urlPath = slug
    .split("/")
    .splice(0, slug.split("/").length - 2)
    .join("/");
  const lastValueInUrl = slug.split("/").splice(-2, 1)[0];
  //If current node is a folder, then corresponding file is index.md, if not path name is same as file name
  const fileName =
    "/" +
    (type === "yaml"
      ? lastValueInUrl + ".yaml"
      : (isCurrentNodeAParent
          ? lastValueInUrl + "/index"
          : lastValueInUrl || "index") + ".md");
  return GIT_URL.BASE + GIT_URL.MASTER + urlPath + fileName;
};
