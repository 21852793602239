import React from "react";
import Helmet from 'react-helmet';
import styled from "styled-components";
import SEO from "./seo";
import Navbar from "./navbar";
import Breadcrumb from "./breadcrumb";
import { getGitHubUrl } from "../../utils/util";

export let breadCrumbs;
export default function Layout({
  title,
  children,
  slug,
  isCurrentNodeAParent,
  type
}) {
  const editUrl = getGitHubUrl(slug, isCurrentNodeAParent, type);
  return (
    <>
      <SEO title={title} keywords={[]} />
      <Helmet
        script={[
          {"src": "https://code.jquery.com/jquery-3.3.1.min.js", type: "text/javascript", crossOrigin: "anonymous"},
          {"src": "https://jira.expedia.biz/s/e204135791bee1a41ec0075e6597788e-T/-4hwqmi/810002/31821a383e773cf59b57f2d43d77812f/4.0.1/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=add0d6ef", type: "text/javascript"},
        ]}
      >
      </Helmet>
      <Navbar />
      <Breadcrumb slug={slug} editUrl={editUrl} />
      <>{children}</>
    </>
  );
}

const Section = styled.section`
  background-size: cover;
  height: 50vh;
  .row {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
