import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import * as ReactBootstrap from "react-bootstrap";
import SearchQuery from "../search/search";
import logo from '../../content/images/eg_logo_white_on_blue.png';

export default function Navbar() {
  return (
    <StaticQuery
      query={graphql`
        query {
          navs: allMarkdownRemark(
            filter: { frontmatter: { show_in_navbar: { eq: true } } }
            sort: { fields: [frontmatter___navbar_order], order: ASC }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={({ navs }) => (
        <ReactBootstrap.Navbar
          variant="dark"
          className="shadow"
          expand="lg"
          sticky="top"
          style={{ backgroundColor: "#000099" }}
        >
          <ReactBootstrap.Container fluid>
            <ReactBootstrap.Navbar.Brand>
              <Link to="/">
                <img
                  src= { logo }
                  height="50px"
                  alt="expedia group logo"
                />
              </Link>
            </ReactBootstrap.Navbar.Brand>
            <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <ReactBootstrap.Navbar.Collapse id="responsive-navbar-nav">
              <ReactBootstrap.Nav className="ml-auto">
                {navs.edges.map(nav => (
                  <ReactBootstrap.Nav.Item key={nav.node.fields.slug}>
                    <Link
                      to={nav.node.fields.slug}
                      className="nav-link px-3"
                      style={{ color: "#ffffff" }}
                    >
                      {nav.node.frontmatter.title}
                    </Link>
                  </ReactBootstrap.Nav.Item>
                ))}
                <SearchQuery />
              </ReactBootstrap.Nav>
            </ReactBootstrap.Navbar.Collapse>
          </ReactBootstrap.Container>
        </ReactBootstrap.Navbar>
      )}
    />
  );
}
