import React, { Component } from "react";
import { Index } from "elasticlunr";
import { StaticQuery, graphql, Link } from "gatsby";
import * as ReactBootstrap from "react-bootstrap";
import styled from "styled-components";

export default function SearchQuery() {
  return (
    <StaticQuery
      query={graphql`
        query {
          siteSearchIndex {
            index
          }
        }
      `}
      render={({ siteSearchIndex }) => (
        <Search searchIndex={siteSearchIndex.index} />
      )}
    />
  );
}

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      results: [],
      show: false
    };
  }

  SearchListComponent = () => (
    <SearchListView>
      <SearchListGroup>
        {this.state.results.map(page => (
          <Link
            key={page.id}
            to={page.path}
            className="list-group-item list-group-item-action py-2 px-3"
          >
            <div className="d-flex w-100 justify-content-between">
              <div className="font-weight-bold text-dark">{page.title}</div>
            </div>
            <small>{page.description}</small>
          </Link>
        ))}
      </SearchListGroup>
    </SearchListView>
  );

  render() {
    return (
      <SearchView>
        <ReactBootstrap.Form className="d-flex align-items-center h-100 my-2 my-lg-0 searchBox">
          <ReactBootstrap.InputGroup size="sm">
            <ReactBootstrap.InputGroup.Prepend>
              <ReactBootstrap.InputGroup.Text id="btnGroupAddon">
                <i className="fas fa-search" />
              </ReactBootstrap.InputGroup.Text>
            </ReactBootstrap.InputGroup.Prepend>
            <ReactBootstrap.FormControl
              type="text"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="btnGroupAddon"
              value={this.state.query}
              onChange={this.search}
            />
          </ReactBootstrap.InputGroup>
        </ReactBootstrap.Form>
        {this.state.show && <this.SearchListComponent />}
      </SearchView>
    );
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex);

  search = event => {
    const query = event.target.value;
    this.index = this.getOrCreateIndex();
    this.setState({
      show: query !== "",
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {
          expand: true
        })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref))
    });
  };
}

const SearchView = styled.span`
  position: relative;
`;

const SearchListView = styled.div.attrs({
  className: "shadow-sm rounded"
})`
  z-index: 99;
  position: absolute;
  width: 150%;
  right: 0;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const SearchListGroup = styled(ReactBootstrap.ListGroup)`
  max-height: 450px;
`;
